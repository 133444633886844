<template>
  <div class="intro">
    <div class="container">
      <div class="cards">
        <div class="alert alert-danger d-flex justify-content-between align-items-center" v-if="computedAlertShowing">
          <span>관리자 계정으로 로그인 해주세요.</span>
          <button class="btn btn-danger" @click="reLogin()">로그아웃</button>
        </div>
        <div class="recommend">
          <div class="title">
            <div class="font-xl mb-2">
              <b>오마이컴퍼니에서</b>
            </div>
            <div class="font-xl">
              <b>쉽고 빠르게 프로젝트를 오픈하세요.</b>
            </div>
          </div>
          <!--          <div class="ai">-->
          <!--            <button class="btn bottom btn-light font-sm" @click="openAI()">-->
          <!--              <div class="d-none d-sm-inline">-->
          <!--                <span>프로젝트 신청이 처음이라면? </span>-->
          <!--                <b>챗GPT로 시작하기</b>-->
          <!--                <span class="ml-1 badge badge-primary">Beta</span>-->
          <!--              </div>-->
          <!--              <div class="d-inline d-sm-none">-->
          <!--                <span class="mr-1 badge badge-primary">Beta</span>-->
          <!--                <b>챗GPT</b>-->
          <!--                <span>로 빠르게 시작하기</span>-->
          <!--              </div>-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
        <div class="content">
          <div class="row">
            <div class="col-lg-3 reward">
              <div class="shadow wrapper">
                <span class="img" style="background-image: url('/assets/img/page.apply.intro.banner1.jpg')"></span>
                <div class="texts">
                  <div class="type font-lg">후원형</div>
                  <p class="desc">신규 상품 및 서비스 개발, 공익 캠페인 등 다양한 프로젝트를 알리고 실행 자금을 모집합니다.</p>
                  <p class="notice font-sm">개인, 단체, 기업 등 누구나 가능</p>
                  <div class="row">
                    <div class="apply col-6 col-lg-12">
                      <router-link to="/apply/rewardIntro" class="btn btn-point apply font-sm">프로젝트 신청하기</router-link>
                    </div>
                    <div class="col-6 col-lg-12">
                      <a :href="$definitions.urls.rewardGuide" target="_blank" rel="noopener noreferrer" class="btn bottom btn-light font-sm">
                        <span>후원형 가이드</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 invest">
              <div class="shadow wrapper">
                <span class="img" style="background-image: url('/assets/img/page.apply.intro.banner2.jpg')"></span>
                <div class="texts">
                  <div class="type font-lg">증권형</div>
                  <p class="desc">우리 기업의 성장과 미래를 함께할 투자자를 모집합니다.</p>
                  <p class="notice font-sm">기업(법인회원)만 신청이 가능</p>
                  <div class="row">
                    <div class="apply col-6 col-lg-12">
                      <router-link class="btn btn-primary apply font-sm" to="/apply/investIntro">프로젝트 신청하기</router-link>
                    </div>
                    <div class="col-6 col-lg-12">
                      <a :href="$definitions.urls.investGuide" target="_blank" rel="noopener noreferrer" class="btn bottom btn-light font-sm">
                        <span>증권형 가이드</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="shadow wrapper">
                <span class="img" style="background-image: url('/assets/img/page.apply.intro.banner6.jpg')"></span>
                <div class="texts">
                  <div class="type font-lg">우리동네 크라우드펀딩</div>
                  <p class="desc">대한민국의 소상공인을 응원합니다. 소상공인이라면 다양한 혜택이 제공되는 우리동네 펀딩에 도전하세요.</p>
                  <p class="notice font-sm">소상공인만 신청이 가능</p>
                  <div class="row">
                    <!--<div class="reward col-6 col-lg-12">
                      <a :href="urls.reward" target="_blank" rel="noopener noreferrer" class="btn btn-bordered-point font-sm">후원형 신청하기</a>
                    </div>-->
                    <div class="invest col">
                      <a :href="urls.invest" target="_blank" rel="noopener noreferrer" class="btn btn-outline-primary apply bottom font-sm mt-0">증권형 신청하기</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--                        <div class="col-lg-3 advertisement">-->
            <!--                          <div class="shadow wrapper">-->
            <!--                            <span class="img" style="background-image: url('/assets/img/page.apply.intro.banner5.jpg')"></span>-->
            <!--                            <div class="texts">-->
            <!--                              <div class="type font-lg">광고 서비스</div>-->
            <!--                              <p class="desc">펀딩성공률을 높이고 싶다면 오마이컴퍼니 광고 서비스를 이용해보세요. 다양한 광고 서비스로 맞춤 타겟을 설정하여 효과적으로 메시지를 전달할 수 있어요.</p>-->
            <!--                              <p class="notice font-sm">펀딩 진행기업만 신청이 가능</p>-->
            <!--                              <div class="row">-->
            <!--                                <div class="col-6 col-lg-12">-->
            <!--                                  <a class="btn btn-primary advertisement font-sm" href="https://www.ohmycompany.com/forms/5d9660c56f75" target="_blank" rel="noopener noreferrer">-->
            <!--                                    <span>광고 서비스 신청하기</span>-->
            <!--                                  </a>-->
            <!--                                </div>-->
            <!--                                <div class="col-6 col-lg-12">-->
            <!--                                  <a class="btn bottom btn-light font-sm" href="https://corp.ohmycompany.com/6f3cfc43-45e9-4a97-b266-ff1a73f5531a" target="_blank" rel="noopener noreferrer">-->
            <!--                                    <span>광고 서비스 소개서</span>-->
            <!--                                  </a>-->
            <!--                                </div>-->
            <!--                              </div>-->
            <!--                            </div>-->
            <!--                          </div>-->
            <!--                        </div>-->
            <div class="col-lg-3 mock">
              <div class="shadow wrapper">
                <span class="img" style="background-image: url('/assets/img/page.apply.intro.banner3.jpg')"></span>
                <div class="texts">
                  <div class="type font-lg">모의 투자</div>
                  <p class="desc">내 아이디어의 실현 가능성을 모의투자를 통해 검증받습니다.</p>
                  <p class="notice font-sm">기관, 기업, 단체 등 가능</p>
                  <router-link to="/apply/rewardIntro?mock" class="btn btn-default apply font-sm">프로젝트 신청하기</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gray">
      <div class="container">
        <div class="texts">
          <div class="bigger color-point">
            <div>세상의 변화를 꿈꾸는 사람들이 함께하는 곳,</div>
            <div>오마이컴퍼니</div>
          </div>
          <div class="smaller">오마이컴퍼니는 국내 대표적인 크라우드펀딩 플랫폼으로 나의 후원과 투자가 세상에 꼭 필요한 곳에 쓰여지길 바라는 여러분들과 함께 합니다.</div>
        </div>
        <div class="images clearfix">
          <div>
            <a>
              <span class="img" :style="{backgroundImage:'url(/assets/img/page.apply.intro.project1.png)'}" alt="프로젝트"></span>
            </a>
            <a>
              <span class="img" :style="{backgroundImage:'url(/assets/img/page.apply.intro.project4.png)'}" alt="프로젝트"></span>
            </a>
            <a>
              <span class="img" :style="{backgroundImage:'url(/assets/img/page.apply.intro.project6.jpg)'}" alt="프로젝트"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="circles clearfix">
      <div class="container">
        <div class="block">
          <span>
            <img src="/assets/ico/page.apply.intro.circle1.svg" alt="전문매니저의 도움"/>
          </span>
          <div class="subject">전문매니저의 도움</div>
          <div class="desc font-sm">펀딩 신청을 완료하면 프로젝트 전문 매니저가 영업일 기준 2일 이내 빠른 피드백으로 프로젝트 오픈을 도와드립니다.</div>
        </div>
        <div class="block">
          <span>
            <img src="/assets/ico/page.apply.intro.circle2.svg" alt="펀딩 페이지 제작 지원"/>
          </span>
          <div class="subject">펀딩 페이지 제작 지원</div>
          <div class="desc font-sm">펀딩 준비가 어려운 분들을 위한 펀딩멘토링, 페이지 디자인 및 제품 사진 촬영을 지원합니다.</div>
        </div>
        <div class="block">
          <span>
            <img src="/assets/ico/page.apply.intro.circle3.svg" alt="손쉬운 프로젝트 홍보"/>
          </span>
          <div class="subject">손쉬운 프로젝트 홍보</div>
          <div class="desc font-sm">오마이컴퍼니가 보유한 다양한 홍보 채널을 통해 프로젝트를 효과적으로 확산시켜드립니다.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import env from "@/scripts/env";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageApplyIntro";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "프로젝트 신청");
    });

    const computedAlertShowing = computed(() => {
      return window.top !== window && !store.state.account.memberAuth.includes("ADMIN");
    });

    const urls = {
      reward: "/forms/22a40c21d000",
      invest: "/forms/5d60dc569d24",
    };

    if (env.location === "prd") {
      urls.reward = "/forms/1b3a3b828ee6";
      urls.invest = "/forms/3aa5c42df88e";
    }

    const openAI = () => {
      store.commit("openModal", {
        name: "ChatGPT",
        params: {
          page: "intro"
        }
      });
    };

    const reLogin = async () => {
      await store.dispatch("logoutAccount", {
        afterLogout: () => router.replace(`/apply/${router.app.$route.query.projectSeq}`),
      });
    };

    return {component, urls, computedAlertShowing, openAI, reLogin};
  }
});
</script>

<style lang="scss" scoped>
.intro {
  .figure {
    position: absolute;
  }

  .cards {
    padding-top: $px60;
    padding-bottom: $px30;

    .recommend {
      margin-bottom: $px35;
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div {
        b {
          font-weight: bold;
        }

        &.ai {
          padding-top: $px6;

          > .btn {
            padding: $px20;

            b {
              color: $colorPrimary;
            }
          }
        }
      }
    }

    .content {
      padding-top: $px20;
      margin: 0 auto;

      > .row > div {
        text-align: center;
        color: #3a3a3a;
        margin-bottom: $px32;
        transition: border-color 0.18s;

        > .wrapper {
          border: $px1 solid #ddd;
          padding: $px197 $px25 $px34 $px25;
          text-align: left;
          position: relative;
          border-radius: $px4;
          min-height: 557px;

          > .img {
            width: 100%;
            height: $px197;
            border-bottom: $px1 solid #eee;
            background-size: cover;
            background-position: center;
            border-radius: $px3 $px3 0 0;
            position: absolute;
            top: 0;
            left: 0;
          }

          .texts {
            position: relative;

            .type {
              padding: $px25 0 $px15 0;
              font-weight: 500;
            }

            .desc {
              padding-top: $px5;
              font-size: $px14;
              min-height: $px87;
            }

            a {
              display: block;
              height: $formHeightLg;
              line-height: calc(#{$formHeightLg} - 2px);
              padding: 0;

              &.bottom {
                margin-top: $px12;
              }
            }

            .notice {
              color: #666;
            }

            .advertisement {
              background-color: $colorAdvertisement;
              border-color: $colorAdvertisement;

              &[disabled]:hover {
                color: #fff;
              }

              &:not([disabled]) {
                &:hover {
                  color: #fff;
                  background-color: $colorAdvertisementActive;
                  border-color: $colorAdvertisementActive;
                }
              }
            }
          }
        }

        &.reward:hover > .wrapper {
          border-color: $colorPoint;
        }

        &.invest:hover > .wrapper {
          border-color: $colorPrimary;
        }

        &.mock:hover > .wrapper {
          border-color: $colorDanger;
        }

        &.advertisement:hover > .wrapper {
          border-color: $colorAdvertisement;
        }

        &.mock {
          .texts a:hover {
            border-color: $colorDanger;
            color: $colorDanger;
            background: #fff;
          }
        }
      }
    }
  }

  .gray {
    background: $colorBackground;
    border-top: $px1 solid #eee;
    border-bottom: $px1 solid #eee;
    overflow: hidden;

    > .container {
      position: relative;

      .texts {
        max-width: $px390;
        padding: $px45 0;

        .bigger {
          font-weight: 600;
          font-size: $px20;
        }

        .smaller {
          word-break: keep-all;
          padding-top: $px20;
        }
      }

      .images {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 700px;
        padding: $px35 0;

        > div {
          > a {
            float: left;
            display: block;
            width: $px174;
            margin: $px15;

            .img {
              width: 100%;
              padding-top: $ratio43Percent;
            }
          }

          &:first-child {
            margin-left: $px-100;
          }
        }
      }
    }
  }

  .circles {
    padding: $px35 0;

    .block {
      position: relative;
      padding: $px10 $px50 $px10 $px115;
      margin: $px15 0;
      float: left;
      width: 50%;

      > span {
        position: absolute;
        top: 0;
        left: 0;
        background: $colorBackground;
        border: $px1 solid #eee;
        border-radius: 50%;
        width: $px90;
        height: $px90;
        padding: $px10;
        text-align: center;

        img {
          height: $px40;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .subject {
        font-weight: 500;
        margin-bottom: $px7;
      }

      .desc {
        color: #666;
      }
    }
  }

  @media(max-width: 1199px) {
    .gray > .container {
      .texts {
        padding: $px35 0 $px15 0;
        max-width: 100%;

        .bigger {
          font-size: $px18;
        }
      }

      .images {
        position: static;
        padding-top: $px15;

        > div {
          > a {
            margin: 0;
            max-width: 33.3333%;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .circles {
      padding: $px30 0 $px40 0;

      .block {
        width: 100%;
        padding-right: $px15;
      }
    }
  }

  @media(min-width: 992px) {
    .content > .row {
      padding: 0 $px8;

      > div {
        padding-left: $px7;
        padding-right: $px7;
      }
    }
  }

  @media(max-width: 991px) {
    .cards {
      .recommend {
        flex-wrap: wrap;
        margin-bottom: $px15;
        text-align: center;

        > div {
          width: 100%;

          &.ai {
            padding-top: $px25;

            .btn {
              padding-top: $px20;
              padding-bottom: $px20;
              width: 100%;
            }
          }
        }
      }

      .content .row > div > .wrapper {
        min-height: auto;

        .texts {
          .desc {
            min-height: auto;
          }

          > .row {
            > .apply {
              padding-right: 0;
            }

            a {
              &.apply {
                margin: 0;
              }

              &.bottom {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .gray > .container {
      .texts {
        .smaller {
          word-break: keep-all;
        }
      }

      .images {
        line-height: 0;

        > div > a {
          width: 50%;
        }
      }
    }
  }
}
</style>